
<template>
	<div class="row">
        <div class="col-12 col-md">
			<label>Estado</label>
			<select class="form-control" v-model="contract.status" v-on:change="changedStatus()">
				<option value="assigned">Asignado</option>
				<option value="processing">En curso</option>
				<option value="contracted">Pendiente de finalización</option>
				<option value="finished">Finalizado</option>
				<option value="review" v-if="role == 'admin'">Revisar de nuevo</option>
			</select>
		</div>
	</div>
</template>
<script>
	export default{
		props: ['contract'],
		data(){
			return {
				role: user_role
			}
		},
		methods: {
			changedStatus(){
				if(this.contract.status != 'finished')
					return;

				var blockByOrigin = (!this.contract.received_call || !this.contract.brought_service || !this.contract.origin_service)
				var blockByProfit = !this.contract.commission_1;
			if(blockByOrigin /*|| blockByProfit*/){
					this.contract.status = 'contracted';
					var message = (this.role == 'admin')?'Debes rellenar la sección origen para poder finalizar el contrato':'No es posible finalizar el contrato, contacta con un administrador';
					this.$swal(
						'¡Atención!',
						message,
						'warning'
					);
				}
			}
		}
	}
</script>