<template>
	<div>
	    <div class="row">
			<div class="col-12 col-md-3">
	            <label>Recibió la llamada</label>
				<select type="text" class="form-control" v-model="contract.received_call">
                    <option value="Gema">Gema</option>
                    <option value="Other">Otros</option>
                </select>
	        </div>
			<div class="col-12 col-md-3">
	            <label>Trajó el servicio</label>
				<select type="text" class="form-control" v-model="contract.brought_service">
                    <option v-for="c in commercials" v-bind:value="c.name">{{ c.name }}</option>
					<option value="other">Otros</option>
                </select>
	        </div>
			<div class="col-12 col-md-3" v-if="contract.received_call == 'Other' && contract.brought_service == 'other'">
	            <label>Colaboradores</label>
				<input spellcheck="true" type="text" class="form-control" v-model="contract.other_origin" placeholder="Otro"/>
	        </div>
	    </div>	    
	    
	    <hr />
		<legend>Origen</legend>
			<div class="col-12">
	            <textarea name="textarea" rows="5" class="form-control" v-model="contract.origin_service"></textarea>
	        </div>
		
	    
	</div>
</template>
<script>
	export default{
		props: ['contract','commercials'],
		data(){
			return {
      			errors:{

          		}
			}
		}
	}
</script>