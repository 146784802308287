<template>
	<div class="modal fade action-sheet opax" id="contactBookModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Ver mensaje</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Nombre de empresa *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="contact.name" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12">
                            <label>Nombre de contacto *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="contact.contact_name" placeholder="Nombre de contacto" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12">
                            <label>Teléfono *</label>
                            <input type="text" class="form-control" v-model="contact.phone" v-bind:class="{'is-invalid':errors.phone}"/>
                        </div>
                    </div>
				</div>
				<div class="modal-footer text-right">
                    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cerrar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('contact-book.add',this.openModal);
            this.$root.$on('contact-book.edit',this.openModal);
        },
        data(){
            return {
            	base: window.urls['contact-book.index'],
                roles:[],
                saving: false,
                contact:{
                    name: null,
                    contact_name: null,
                    phone: null
                },
                errors: {
                    type: false,
                    name: false,
                    phone: false,
                    mobile_phone: false,
                    email: false,
                },
            }
        },
        methods:{
        	openModal(data){
                if(data){
                    this.contact = {
                        id: data.id,
                        name: data.name,
                        contact_name: data.contact_name,
                        phone: data.phone
                    };
                }else{
                    this.contact = {
                        name: null,
                        contact_name: null,
                        phone: null
                    };
                }
                this.resetErrors();
        		$('#contactBookModal').modal({'backdrop':'static'});
        	},
            budget(){
                this.$root.$emit('contact-book.budget.edit',this.contact);
            },
            resetErrors(){
                this.errors = {
                    name: false,
                    contact_name: false,
                    phone: false,
                };
            },
            validate(){
                var valid = true;
                this.errors.name = this.contact.name == null || this.contact.name == "";
                this.errors.contact_name = this.contact.contact_name == null || this.contact.contact_name == "";
                this.errors.phone = this.contact.phone == null || this.contact.phone == "";

                for(var i in this.errors)
                    valid &= !this.errors[i];

                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm = this;
                if(this.contact.id)
                    url += '/' + this.contact.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.contact,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving = false;
                    vm.$root.$emit('contact-book.updated');
                    vm.cancel();
                    vm.$swal(
                        'Correcto',
                        'Se ha guardado el contacto',
                        'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#contactBookModal').modal('hide');
            }
        }
    }
</script>
