<template>
	<div>
		<div class="my-3">
			<button class="btn btn-info" v-on:click="addInfo">Añadir información</button>
		</div>
		<v-server-table ref="dataTable" v-bind:url="base+'/table'" v-bind:columns="columns" v-bind:options="options" v-bind:template="'bootstrap4'" @loading="loadingTable" @loaded="loadedTable">
			<div slot="created_at" slot-scope="props">
	            {{ props.row.created_at|dayFormat }}
	        </div>
	        <div slot="id_user" slot-scope="props">
	            {{ props.row.user?props.row.user.name:'' }}
	        </div>
            <div slot="type" slot-scope="props">
                {{ props.row.type|transformType }}
                <button class="btn btn-success btn-sm float-right" v-if="props.row.type == 'incident' && props.row.status != 'solved'" @click="solved(props.row)">
                    Resuelta
                </button>
            </div>
		</v-server-table>
	</div>
</template>
<script>
	export default{
		props: ['contract'],
		mounted(){
			this.$root.$on('contract.info.updated', this.refreshTable);
		},
		data(){
			return {
				base: window.urls['contracts-info.index'],
				loading:true,
                firsttime:true,
				columns: ['type','created_at','message','id_user','id_contract'],
		        options:{
                    columnsClasses: {},
                    sortIcon: {
                        base : 'float-right fa',
                        is: 'fa-sort',
                        up: 'fa-sort-up',
                        down: 'fa-sort-down'
                    },
                	headings:{
                        'type': 'Tipo',
                        'created_at': 'Fecha',
                        'message': 'Comentario',
                        'id_user': 'Usuario'
                    },
                    skin: 'table table-striped',
                    filterable:true,
		        	texts: {
                        count: '',
                        filter: 'Buscar: ',
                        filterPlaceholder: "...",
                        defaultOption: 'Elegir {column}',
                        filterBy: "Filtrar por {column}",
                        loading: 'Cargando...',
                        limit: "Mostrando:",
                        noResults: 'No hay resultados'
                    },
                	orderBy:{
                		ascending: 0,
                		column: 'created_at'
                    },
                    initFilters: {
                    	id_contract: this.contract.id
                    },
                    hiddenColumns: ['id_contract'],
                    filterByColumn: true,
                    rowClassCallback: row => {
                        if(row.type == 'incident' && row.status != 'solved')
                            return "table-danger";
                    },
		        }
			}
		},
		filters:{
            dayFormat(input){
                if(input) return moment(input).format('DD/MM/YYYY HH:mm')
                return "";
            },
            transformType(input){
                switch(input){
                    case 'service': return 'Info. servicio'; break;
                    case 'route': return 'Info. ruta'; break;
                    case 'contracts': return 'Info. contratación'; break;
                    case 'incident': return 'Incidencias'; break;
                }
            }
        },
        methods: {
        	addInfo(){
        		this.$root.$emit('contract.info.add', {
        			contract: this.contract
        		});
        	},
        	loadingTable(){
                this.loading = true;
            },
            loadedTable(data){
                this.loading = false;
                this.firsttime = false;
            },
            refreshTable(){
            	this.$refs.dataTable.getData();
            },
            solved(row){
                var url = this.base + '/' + row.id;
                var vm = this;
                this.saving = true;
                row.status = 'solved'
                
                axios({
                    method: 'post',
                    url: url,
                    data: row,
                    responseType: 'json',
                }).then(function(response){
                    vm.$refs.dataTable.getData();
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            }
        }
	}
</script>