<template>
	<div>
	    <div class="row">
            <div class="col-12 col-md-2">
                <label>Tipo de documento *</label>
                <select class="form-control" v-model="deceased.document_type" v-bind:class="{'is-invalid':errors.document_type}">
                    <option value="dni">DNI</option>
                    <option value="passport">Pasaporte</option>
                    <option value="other">Otros</option>
                </select>
            </div>
	    	<div class="col-12 col-md-2">
	            <label>Documento de identidad *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.dni" placeholder="DNI/NIE/Pasaporte" v-on:change="validateCif()" v-bind:class="{'is-invalid':errors.dni}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Nombre *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.name" v-on:blur="firstLetterOnUpperCase('name')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}" />
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Primer apellido *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.surname" v-on:blur="firstLetterOnUpperCase('surname')" placeholder="Primer apellido" v-bind:class="{'is-invalid':errors.surname}" />
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Segundo apellido</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.lastname" v-on:blur="firstLetterOnUpperCase('lastname')" placeholder="Segundo apellido" />
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Estado civil</label>
	            <select type="text" class="form-control" v-model="deceased.civil">
                    <option value="unknown">No consta</option>
	                <option value="married">Casado/a</option>
	                <option value="divorced">Divorciado/a</option>
	                <option value="union">Pareja de hecho</option>
	                <option value="separated">Separado/a</option>
	                <option value="single">Soltero/a</option>
	                <option value="widow">Viudo/a</option>
	            </select>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Género *</label>
	            <select type="text" class="form-control" v-model="deceased.gender" v-bind:class="{'is-invalid':errors.gender}">
	                <option value="male">Hombre</option>
	                <option value="female">Mujer</option>
	            </select>
	        </div>
	    </div>
	    <hr class="my-3"/>
	    <div class="row">
	        <div class="col-12 col-md-4">
	            <label>Nacionalidad *</label>
                <autocomplete-component v-model="deceased.id_nationality" v-bind:options="countries" v-bind:key-code="'id'" v-bind:key-label="'name'"></autocomplete-component>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Provincia de nacimiento *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.birth_province" placeholder="Provincia de nacimiento" v-bind:class="{'is-invalid':errors.birth_province}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Municipio de nacimiento *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.birth_municipality" placeholder="Municipio de nacimiento" v-bind:class="{'is-invalid':errors.birth_municipality}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Fecha de nacimiento *</label>
	            <datepicker v-model="birthDate" style="display: block;width: 100%"  v-bind:lang="lang" format="DD/MM/YYYY" type="date" placeholder="Fecha de nacimiento" v-on:change="changeBirthdate" v-bind:input-class="{'form-control is-invalid':errors.birthdate,'form-control':!errors.birthdate}"></datepicker>
	        </div>
	    </div>
	    <hr class="my-3"/>
	    <div class="row">
	        <div class="col-12">
	            <label>Dirección *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.address" placeholder="Dirección" v-bind:class="{'is-invalid':errors.address}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Código Postal *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.postcode" placeholder="Código postal" v-bind:class="{'is-invalid':errors.postcode}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Provincia *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.state" placeholder="Provincia" v-bind:class="{'is-invalid':errors.state}"/>
	        </div>
	        <div class="col-12 col-md-4">
	            <label>Población *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.city" placeholder="Población" v-bind:class="{'is-invalid':errors.city}"/>
	        </div>
	    </div>
	    <hr class="my-3"/>
	    <div class="row">
	        <div class="col-12 col-md-6">
	            <label>Nombre del padre *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.father" placeholder="Nombre del padre" v-bind:class="{'is-invalid':errors.father}"/>
	        </div>
	        <div class="col-12 col-md-6">
	            <label>Nombre de la madre *</label>
	            <input spellcheck="true" type="text" class="form-control" v-model="deceased.mother" placeholder="Nombre de la madre" v-bind:class="{'is-invalid':errors.mother}"/>
	        </div>
	    </div>
	</div>
</template>
<script>
	import Datepicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es';
	export default{
		components: { Datepicker },
		props: ['countries'],
		data(){
			return {
				birthDate: null,
				isValidCif: true,
                saveInterval: null,
				deceased: {
                    is_solicitant: null,
                    id_nationality: null,
                    name: null,
                    surname: null,
                    lastname: null,
                    dni: null,
                    civil: null,
                    gender: null,
                    type: 'deceased',
                    //birthplace: null,
                    birth_province: null,
                    birth_municipality: null,
                    birthdate: null,
                    address: null,
                    postcode: null,
                    city: null,
                    state: null,
                    father: null,
                    mother: null,
                },
				errors: {
					id_nationality: false,
                    name: false,
                    surname: false,
                    lastname: false,
                    document_type: false,
                    dni: false,
                    gender: false,
                    type: false,
                    //birthplace: false,
                    birth_province: false,
                    birth_municipality: false,
                    birthdate: false,
                    address: false,
                    postcode: false,
                    city: false,
                    state: false,
				},
				lang: {
                    formatLocale: {
                      firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
			}
		},
		mounted(){
			this.$root.$on('form.initialize.deceased',this.initializeDeceased);
			this.$root.$on('contracts.section.changed.deceased',this.validate);
		},
        watch: {
            deceased:{
                deep: true,
                handler(data){
                    var mandatory = [
                        'id_nationality',
						'name',
						'surname',
                        'document_type',
						'dni',
						'gender',
						'type',
                        'birth_province',
                        'birth_municipality',
						'birthdate',
						'address',
						'postcode',
						'city',
						'state',
						'father',
						'mother',
                    ];
                    var filled = 0;
                    var fullfilled = false;
                    for(var i = 0; i < mandatory.length; i++){
                        var index = mandatory[i];
                        if(this.deceased[index] != null && this.deceased[index] != ""){
                            if(this.errors[index]) this.errors[index] = false;
                            filled++;
                        }
                    }
                    fullfilled = filled == mandatory.length;
                    if(fullfilled){
                        var vm = this;
                        if(this.saveInterval != null) clearTimeout(this.saveInterval);
                        this.saveInterval = setTimeout(function(){
                            vm.save();
                        }, 1500);
                        
                    }
                }
            }
        },
		methods: {
			initializeDeceased(data){
                if(data && data.deceased){
                    this.deceased={
                        id: data.deceased.id,
                        id_nationality: data.deceased.id_nationality,
                        name: data.deceased.name,
                        surname: data.deceased.surname,
                        lastname: data.deceased.lastname,
                        document_type: data.deceased.document_type,
                        dni: data.deceased.dni,
                        civil: data.deceased.civil,
                        gender: data.deceased.gender,
                        type: data.deceased.type,
                        //birthplace: data.deceased.birthplace,
                        birth_province: data.deceased.birth_province,
                        birth_municipality: data.deceased.birth_municipality,
                        birthdate: data.deceased.birthdate,
                        address: data.deceased.address,
                        postcode: data.deceased.postcode,
                        city: data.deceased.city,
                        state: data.deceased.state,
                        father: data.deceased.father,
                        mother: data.deceased.mother,
                    };
                	this.birthDate = new Date(this.deceased.birthdate);
                    this.isValidCif = true;
                }else{
                    this.deceased={
                        is_solicitant: "0",
                        id_nationality: 205,
                        name: null,
                        surname: null,
                        lastname: null,
                        document_type: null,
                        dni: null,
                        civil: null,
                        gender: null,
                        type: 'deceased',
                        //birthplace: null,
                        birth_province: null,
                        birth_municipality: null,
                        birthdate: null,
                        address: null,
                        postcode: null,
                        city: null,
                        state: null,
                        father: null,
                        mother: null,
                    };
                    this.birthDate = null;
                    this.isValidCif = true;
                }
                this.resetErrors();
            },
            resetErrors(){
                this.errors={
                    id_nationality: false,
                    name: false,
                    surname: false,
                    lastname: false,
                    document_type: false,
                    dni: false,
                    gender: false,
                    type: false,
                    //birthplace: false,
                    birth_province: false,
                    birth_municipality: false,
                    birthdate: false,
                    address: false,
                    postcode: false,
                    city: false,
                    state: false,
                    father: false,
					mother: false
                };
            },
            changeBirthdate(evt){
                if(evt!=null) this.deceased.birthdate=moment(evt).format('YYYY-MM-DD');
                else this.deceased.birthdate=null;
            },
            checkIfNumber(evt){
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            firstLetterOnUpperCase(field){
            	if(this.deceased[field] == null)
            		return ;
                var aux=this.deceased[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.deceased[field]=final;
            },
            validateCif(){
                this.isValidCif = this.deceased.dni != null;
                if(this.isValidCif && this.deceased.id_nationality == 205){
                    var regex = new RegExp('([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])');
                    this.isValidCif = this.deceased.dni.match(regex);
                }
                if(!this.isValidCif){
                    this.showValidCifAlert();
                }
                this.errors.dni = !this.isValidCif;
            },
            validate(){
                var valid = true;
                var errors = 0;
                this.errors.id_nationality = this.deceased.id_nationality == null || this.deceased.id_nationality == "";
                this.errors.name = this.deceased.name == null || this.deceased.name == "";
                this.errors.surname = this.deceased.surname == null || this.deceased.surname == "";
                this.errors.document_type = this.deceased.document_type == null || this.deceased.document_type == "";
                this.errors.dni = !this.isValidCif || this.deceased.dni == null || this.deceased.dni == "";
                this.errors.gender = this.deceased.gender == null || this.deceased.gender == "";
                this.errors.type = this.deceased.type == null || this.deceased.type == "";
                //this.errors.birthplace = this.deceased.birthplace == null || this.deceased.birthplace == "";
                this.errors.birth_province = this.deceased.birth_province == null || this.deceased.birth_province == "";
                this.errors.birth_municipality = this.deceased.birth_municipality == null || this.deceased.birth_municipality == "";
                this.errors.birthdate = this.deceased.birthdate == null || this.deceased.birthdate == "";
                this.errors.address = this.deceased.address == null || this.deceased.address == "";
                this.errors.postcode = this.deceased.postcode == null || this.deceased.postcode == "";
                this.errors.city = this.deceased.city == null || this.deceased.city == "";
                this.errors.state = this.deceased.state == null || this.deceased.state == "";
                this.errors.father = this.deceased.father == null || this.deceased.father == "";
                this.errors.mother = this.deceased.mother == null || this.deceased.mother == "";
                for(var i in this.errors){
                	if(this.errors[i])
                		errors++;
                }
                valid = errors == 0;
                this.$root.$emit('contracts.section.validated',{
                	section: 'deceased',
                	errors: errors
                });
                if(valid){
                    this.save();
                }
            },
            save(){
				var deceased = JSON.parse(JSON.stringify(this.deceased));
                this.$root.$emit('contracts.section.set.deceased', deceased)
            }
		}
	}
</script>