<template>
    <div>
        <p v-if="loading">Cargando</p>
        <div class="row" v-if="!loading">          
            <div class="col-12 col-lg-12">
                <div class="col-12 col-md-3">
                <table  class="table table-bordered">
                        <tr>
                            <th>
                                QUIÉN RECIBIÓ LA LLAMADA
                            </th>
                            <td>
                                {{ contract.received_call?contract.received_call:"NO SE INDICA" }}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                QUIÉN TRAJÓ EL SERVICIO
                            </th>
                            <td>
                                {{ contract.brought_service?contract.brought_service:"NO SE INDICA" }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <table  class="table table-bordered">
                    <tr>
                        <th></th>
                        <th>
                            VENTA
                        </th>
                        <th>COSTE</th>
                    </tr>
                    <tr>
                        <th>
                            BASE GENERAL
                        </th>
                        <td>
                            {{ selling.general.sell|formatMoneyString }}
                        </td>
                        <td>
                            {{ selling.general.cost|formatMoneyString }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            BASE REDUCIDA
                        </th>
                        <td>
                            {{ selling.reduced.sell|formatMoneyString }}
                        </td>
                        <td>
                            {{ selling.reduced.cost|formatMoneyString }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            BASE EXENTA
                        </th>
                        <td>
                            {{ selling.exent.sell|formatMoneyString }}
                        </td>
                        <td>
                            {{ selling.exent.cost|formatMoneyString }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            TOTAL IVA
                        </th>
                        <td>
                            {{ selling.iva.sell|formatMoneyString }}
                        </td>
                        <td>
                            {{ selling.iva.cost|formatMoneyString }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            TOTAL FACTURA
                        </th>
                        <td>
                            {{ selling.total.sell|formatMoneyString }}
                        </td>
                        <td>
                            {{ selling.total.cost|formatMoneyString }}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-12 col-lg-6">
                <table  class="table table-bordered">
                    <tr>
                        <th>
                            DER. ENTERRAMIENTO
                        </th>
                        <td>
                            {{ contract.service_payment_cemetery|formatMoneyString }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            PRECIO DE LA SALA
                        </th>
                        <td>
                            {{ contract.service_room_price|formatMoneyString }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            HONORARIOS PARRO
                        </th>
                        <td>
                            {{ contract.mounting_payment_priest|formatMoneyString }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            COMISIÓN 1
                        </th>
                        <td>
                            <input type="number" class="form-control form-control-sm" v-model="contract.commission_1" />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            COMISIÓN 2
                        </th>
                        <td>
                            <input type="number" class="form-control form-control-sm" v-model="contract.commission_2" v-bind:disabled="!contract.commission_1"/>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            COMISIÓN 3
                        </th>
                        <td>
                            <input type="number" class="form-control form-control-sm" v-model="contract.commission_3" v-bind:disabled="!contract.commission_2"/>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            TOTAL
                        </th>
                        <td>
                            {{ calculateTotalToPay()|formatMoneyString }}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-12">
                <table class="table table-bordered">
                    <tr>
                        <th>
                            TOTAL VENTA (sin IVA)
                        </th>
                        <th>
                            TOTAL COSTES (sin IVA)
                        </th>
                        <th>
                            TOTAL PAGOS
                        </th>
                        <th>
                            TOTAL BENEFICIO
                        </th>
                        <th>
                            % BENEFICIO
                        </th>
                    </tr>
                    <tr>
                        <th>
                            {{ calculateTotalSelled()|formatMoneyString }}
                        </th>
                        <th>
                            {{ calculateTotalCostSelled()|formatMoneyString }}
                        </th>
                        <th>
                            {{ calculateTotalToPay()|formatMoneyString }}
                        </th>
                        <th>
                            {{ calculateTotalBenefit()|formatMoneyString }}
                        </th>
                        <th>
                            {{ calculateTotalBenefitPercent() }}%
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        props: [
            'contract',
            'contracts'
        ],
        data(){
            return {
                basePortfolio: window.urls['portfolio.index'],
                loading: true,
                portfolio: [],
                products: [],
                ivas: {
                    general: 21,
                    reduced: 10,
                    exent: 0
                },
                selling: {
                    general: {
                        sell: 0,
                        cost: 0
                    },
                    reduced: {
                        sell: 0,
                        cost: 0
                    },
                    exent: {
                        sell: 0,
                        cost: 0
                    },
                    supplied: {
                        sell: 0,
                        cost: 0
                    },
                    iva: {
                        sell: 0,
                        cost: 0
                    },
                    total: {
                        sell: 0,
                        cost: 0
                    },
                },
                paying: {

                }
            }
        },
        filters: {
            formatMoneyString(input){
                if(!input)
                    return "0,00";

                var number = Math.round(input * 100) / 100;
                var result = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
                return result.replace("€", "").trim();
            }
        },
        mounted(){
            this.$root.$on('contracts.edit', this.refreshInformation);
            this.$root.$on('contracts.budgets.updated', this.refreshInformation);
            this.$root.$on('contracts.section.changed.new.profit', this.refreshInformation);
            this.getPortfolio();
        },
        methods: {
            getPortfolio(){
        		var vm = this;
                this.loading = true;
        		var url = this.basePortfolio + '/table?limit=100000&page=1&orderBy=name&ascending=1&query={active:true}';
        		axios({
                    method: 'get',
                    url: url,
                    responseType: 'json',
                }).then(function(response){
                    vm.loading = false;
                    vm.portfolio = response.data.data;
                });
            },
            refreshInformation(){
                if(this.loading || !this.contracts)
                    return;

                this.extractProducts();
                this.calculateGeneral();
                this.calculateReduced();
                this.calculateExent();
                this.calculateSupplied();
                this.calculateIva();
                this.calculateTotal();
            },
            extractProducts(){
                this.products = [];
                for(var i in this.contracts){
                    for(var j in this.contracts[i].rows){
                        var product = this.contracts[i].rows[j];
                        var portfolio = this.getProductById(product.id_portfolio);
                        this.products.push({
                            type: portfolio?portfolio.type:'service',
                            name: product.concept,
                            price: product.price,
                            p_taxes: product.p_tax,
                            taxes: product.tax,
                            discount: product.discount,
                            qty: product.qty,
                            total: product.total,
                            cost_price: portfolio?portfolio.cost_price:0,
                            cost_taxes: portfolio?portfolio.cost_taxes:0,
                            cost_total: portfolio?portfolio.cost_total:0,
                        });
                            
                    }
                }
            },
            getProductById(idProduct){
                if(idProduct == 0)
                    return null;
                for(var i in this.portfolio){
                    if(this.portfolio[i].id == idProduct)
                        return this.portfolio[i];
                }
                return null;
            },
            calculateGeneral(){
                this.selling.general.sell = 0;
                this.selling.general.cost = 0;
                for(var i in this.products){
                    if(this.products[i].p_taxes == this.ivas.general){
                        this.selling.general.sell += (this.products[i].price * this.products[i].qty) - this.products[i].discount;
                        this.selling.general.cost += this.products[i].cost_price * this.products[i].qty;
                    }
                }
            },
            calculateReduced(){
                this.selling.reduced.sell = 0;
                this.selling.reduced.cost = 0;
                for(var i in this.products){
                    if(this.products[i].p_taxes == this.ivas.reduced){
                        this.selling.reduced.sell += (this.products[i].price * this.products[i].qty) - this.products[i].discount;
                        this.selling.reduced.cost += this.products[i].cost_price * this.products[i].qty;
                    }
                }
            },
            calculateExent(){
                this.selling.exent.sell = 0;
                this.selling.exent.cost = 0;
                for(var i in this.products){
                    if(this.products[i].p_taxes == this.ivas.exent){
                        this.selling.exent.sell += (this.products[i].price * this.products[i].qty) - this.products[i].discount;
                        this.selling.exent.cost += this.products[i].cost_price * this.products[i].qty;
                    }
                }
            },
            calculateSupplied(){
                this.selling.supplied.sell = 0;
                this.selling.supplied.cost = 0;
                for(var i in this.products){
                    if(this.products[i].type == 'supplied'){
                        this.selling.supplied.sell += (this.products[i].price * this.products[i].qty) - this.products[i].discount;
                        this.selling.supplied.cost += this.products[i].cost_price * this.products[i].qty;
                    }
                }
            },
            calculateIva(){
                this.selling.iva.sell = 0;
                this.selling.iva.cost = 0;
                for(var i in this.products){
                    this.selling.iva.sell += this.products[i].taxes;
                    this.selling.iva.cost += this.products[i].cost_price * (this.products[i].cost_taxes / 100);
                }
            },
            calculateTotal(){
                this.selling.total.sell = 0;
                this.selling.total.cost = 0;
                for(var i in this.products){
                    this.selling.total.sell += this.products[i].total;
                    this.selling.total.cost += this.products[i].cost_total;
                }
            },
            calculateTotalToPay(){
                var service_payment_cemetery = this.contract.service_payment_cemetery?parseFloat(this.contract.service_payment_cemetery):0;
                var service_room_price = this.contract.service_room_price?parseFloat(this.contract.service_room_price):0;
                var mounting_payment_priest = this.contract.mounting_payment_priest?parseFloat(this.contract.mounting_payment_priest):0;
                var commission_1 = this.contract.commission_1?parseFloat(this.contract.commission_1):0;
                var commission_2 = this.contract.commission_2?parseFloat(this.contract.commission_2):0;
                var commission_3 = this.contract.commission_3?parseFloat(this.contract.commission_3):0;
                var total = service_payment_cemetery + service_room_price + mounting_payment_priest + commission_1 + commission_2 + commission_3;
                return total;
            },
            calculateTotalSelled(){
                var total = 0;
                for(var i in this.products){
                    total += (this.products[i].price * this.products[i].qty) - this.products[i].discount;
                }
                return total;
            },
            calculateTotalCostSelled(){
                var total = 0;
                for(var i in this.products){
                    total += this.products[i].cost_price * this.products[i].qty;
                }
                return total;
            },
            calculateTotalBenefit(){
                var totalSelled = this.calculateTotalSelled();
                var totalCost = this.calculateTotalCostSelled();
                var totalPayed = this.calculateTotalToPay();
                return totalSelled - totalCost - totalPayed;
            },
            calculateTotalBenefitPercent(){
                var totalBenefit = this.calculateTotalBenefit();
                var totalSelled = this.calculateTotalSelled();
                var percent = 100 * totalBenefit / totalSelled;
                return Math.round(percent);
            },
        }
    }
</script>