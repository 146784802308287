<template>
    <v-server-table ref="dataTable" v-bind:url="base+'/table'" v-bind:columns="columns" v-bind:options="options" v-bind:template="'bootstrap4'" @loading="loadingTable" @loaded="loadedTable">
        <div slot="created_at" slot-scope="props">
            {{ props.row.created_at|dayFormat }}
        </div>
        <div slot="status" slot-scope="props">
            <span class="badge" v-bind:class="getContractStatusClass(props.row.status)">
                {{ props.row.status|transformContractStatus }}
            </span>
        </div>
        <div slot="status_e" slot-scope="props">
            -
        </div>
        <div slot="deceased" slot-scope="props">
            {{ props.row.deceased?props.row.deceased.name + ' ' + props.row.deceased.surname:'-' }}
        </div>
        <div slot="commercial" slot-scope="props">
            <div v-if="props.row.commercial">
                <a href="javascript:void(0)" v-if="isAdmin" v-on:click="changeCommercial(props.row)">
                    {{ props.row.commercial.name + ' ' + props.row.commercial.lastname }}
                </a>
                <span v-if="!isAdmin">
                    {{ props.row.commercial.name + ' ' + props.row.commercial.lastname }}
                </span>
            </div>
            <div v-if="!props.row.commercial">
                -
            </div>
        </div>
        <div slot="deceasing_place" slot-scope="props">
            {{ props.row.deceasing_place?props.row.deceasing_place:'-' }}
        </div>
        <div slot="op" slot-scope="props">
            -
        </div>
        <div slot="documentation" slot-scope="props">
            <span v-if="props.row.documentation.length > 0" class="text-success">
                <i class="fas fa-check"></i>
            </span>
            <span v-if="props.row.documentation.length == 0" class="text-danger">
                <i class="fas fa-times"></i>
            </span>
        </div>
        <div slot="installation" slot-scope="props">
            -
        </div>
        <div slot="room" slot-scope="props">
            -
        </div>
        <div slot="chamber" slot-scope="props">
            -
        </div>
        <div slot="coordinator" slot-scope="props">
            -
        </div>
        <div slot="asesor" slot-scope="props">
            -
        </div>
        <div slot="part_status" slot-scope="props">
            -
        </div>
        <div slot="id" slot-scope="props">
            {{ props.row.number?props.row.number:'P' + props.row.id }}
        </div>
        <div slot="number" slot-scope="props">
            <a href="javascript:void(0)" v-if="isAdmin && props.row.number" v-on:click="changeNumber(props.row)">
                P {{ props.row.number }}
            </a>
            <span v-if="!isAdmin || !props.row.number">
                {{ props.row.number?props.row.number:'P' + props.row.id }}
            </span>
        </div>
        <div slot="actions" slot-scope="props">
            <a href="javascript:void(0)" v-if="isAdmin || props.row.status != 'finished'" v-on:click="edit(props.row)" class="text-inverse text-sm" title="" data-toggle="tooltip" data-original-title="Editar"><i class="fas fa-edit"></i></a>
            <!--<a v-if="props.row.solicitant" href="javascript:void(0)" v-on:click="share(props.row)" class="ml-2 text-inverse text-sm" title="" data-toggle="tooltip" data-original-title="Eliminar"><i class="fas fa-share"></i></a>-->
            <a href="javascript:void(0)" v-if="isAdmin" v-on:click="deleteRow(props.row)" class="ml-2 text-inverse text-sm" title="" data-toggle="tooltip" data-original-title="Eliminar"><i class="fas fa-trash"></i></a>
        </div>
    </v-server-table>
</template>
<script>
    export default {
        data(){
        	return {
        		base:window.urls['contracts.index'],
                isAdmin: user_role == 'admin',
                loading:true,
                firsttime:true,
                columns: [
                    'actions',
                    'status',
                    'id',
                    'number',
                    'created_at',
                    'deceased',
                    'commercial',
                    'deceasing_place',
                    'solcitant',
                    'op',
                    'documentation',
                    'status_e',
                    'service_intermediate_instalation',
                    'service_estimated',
                    'service_chamber_estimated',
                    'coordinator',
                    'asesor',
                    'part_status',
                    'id_hospital'
                ],
		        options:{
                    columnsClasses: {
                        'actions':'column-actions'
                    },
                    sortIcon: {
                        base : 'float-right fa',
                        is: 'fa-sort',
                        up: 'fa-sort-up',
                        down: 'fa-sort-down'
                    },
                	headings:{
                        'actions': '',
                        'status': 'Estado',
                        'id': 'ID',
                        'number': 'Nº Factura',
                        'created_at': 'Fecha',
                        'deceased': 'Fallecido',
                        'commercial': 'Comercial',
                        'deceasing_place': 'Lugar de fallecimiento',
                        'solcitant': 'Cliente',
                        'op': 'Op',
                        'documentation': 'Doc',
                        'status_e': 'Estado €',
                        'service_intermediate_instalation': 'Instalación',
                        'service_estimated': 'Sala',
                        'service_chamber_estimated': 'Cámara',
                        'coordinator': 'Asignado Ccord.',
                        'asesor': 'Asignado asesor',
                        'part_status': 'Estado partidas'
                    },
                    skin: 'table table-striped',
                    filterable:true,
		        	texts: {
                        count: '',
                        filter:'Buscar: ',
                        filterPlaceholder:"...",
                        defaultOption:'Elegir {column}',
                        filterBy:"Filtrar por {column}",
                        loading:'Cargando...',
                        limit:"Mostrando:",
                        noResults:'No hay resultados'
                    },
                    sortable: [,'id','created_at','deceased','commercial','deceasing_place','solcitant','op','documentation','status','service_intermediate_instalation','service_estimated','service_chamber_estimated','coordinator','asesor','part_status','id_hospital'],
                    hiddenColumns: ['id_hospital'],
                	orderBy:{
                		ascending:0,
                		column:'created_at'
                    },
                    filterByColumn: true,
                    rowClassCallback: row => {
                        if(row.incidents_count > 0)
                            return "table-danger";
                    }
		        }
        	}
	  	},
	  	filters:{
            dayFormat(input){
                if(input) return moment(input).format('DD/MM/YYYY')
                return "";
            },
            transformGender(input){
                switch(input){
                    case 'male': return 'Hombre'; break;
                    case 'female': return 'Mujer'; break;
                    default: return 'No definido'; break;
                }
            },
            transformStatus(input){
                switch(input){
                    case 'active': return 'Activo'; break;
                    case 'inactive': return 'Baja'; break;
                    default: return 'No definido'; break;
                }
            },
            transformContractStatus(input){
                switch(input){
                    case 'assigned': return 'Asignado'; break;
                    case 'processing': return 'En curso'; break;
                    case 'contracted': return 'Contratado'; break;
                    case 'finished': return 'Finalizado'; break;
                    case 'review': return 'Revisar de nuevo'; break;
                }
            }
        },
        mounted(){
            this.$root.$on('contracts.report',this.downloadReport);
        	this.$root.$on('contracts.search',this.search);
            this.$root.$on('contracts.updated',this.refreshTable);
            this.$root.$on('contracts.budgets.updated',this.refreshTable);
            this.$root.$on('signed-documents.updated',this.refreshTable);
            this.$root.$on('contracts.updated-columns',this.updateColumns);
        },
        methods:{
            addColumnsClasses(){
                for(var i in this.columns)
                    this.options.columnsClasses[this.columns[i]]=this.columns[i];
            },
            loadingTable(){
                this.loading=true;
            },
            loadedTable(data){
                this.loading=false;
                this.firsttime=false;
                this.$root.$emit('contracts.loaded',data.data);
            },
            search(filter){
                this.$refs.dataTable.setFilter(filter);
            },
            refreshTable(){
                this.$refs.dataTable.getData();
            },
            updateColumns(columns){
                var aux=JSON.parse(columns);
                this.columns=Object.keys(aux);
                this.options.headings=aux;
            },
            edit(row){
                this.$root.$emit('contracts.edit',row);
            },
            share(row){
                this.$root.$emit('contracts.share',row);
            },
            changeNumber(row){
                this.$root.$emit('contracts.edit.number',row);
            },
            changeCommercial(row){
                this.$root.$emit('contracts.edit.commercial',row);
            },
            downloadReport(){
                var win = window.open(this.base+'/report', '_blank');
                win.focus();
            },
            getContractStatusClass(status){
                switch(status){
                    case 'assigned': return 'badge-info'; break;
                    case 'processing': return 'badge-warning'; break;
                    case 'contracted': return 'badge-success-dark'; break;
                    case 'finished': return 'badge-success'; break;
                    case 'review': return 'badge-danger'; break;
                }
            },
        	deleteRow(row){
	  			var vm=this;
	  			this.$swal({
					title: "Eliminar",
					text: "No podrás deshacer esta acción",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí',
				}).then((result) => {
                    if(result.value){
                        axios({
		                    method: 'delete',
		                    url: this.base+'/'+row.id,
		                    responseType: 'json',
		                }).then(function(response){
		                    vm.$refs.dataTable.refresh();
		                })
		                .catch(function (error) {
		                    vm.$swal(
		                      '¡Ups!',
		                      'Parece que algo fue mal',
		                      'error'
		                    );  
		                });
                    }
				})
	  		}
        }
    }
</script>
