<template>
    <v-server-table ref="dataTable" v-bind:url="base+'/table'" v-bind:columns="columns" v-bind:options="options" v-bind:template="'bootstrap4'" @loading="loadingTable" @loaded="loadedTable">
        <div slot="budget_date" slot-scope="props">
            {{ props.row.budget_date|dayFormat }}
        </div>
        <div slot="actions" slot-scope="props">
            <a href="javascript:void(0)" v-on:click="edit(props.row)" class="text-inverse text-sm" title="" data-toggle="tooltip" data-original-title="Editar"><i class="fas fa-edit"></i></a>
            &nbsp;
            &nbsp;
            <a href="javascript:void(0)" v-on:click="deleteRow(props.row)" class="text-inverse text-sm" title="" data-toggle="tooltip" data-original-title="Eliminar"><i class="fas fa-trash"></i></a>
        </div>
    </v-server-table>
</template>
<script>
    export default {
        data(){
        	return {
        		base:window.urls['competitions.index'],
                loading:true,
                firsttime:true,
                filter: {},
                orderBy: null,
                ascending: null,
                columns: [
                    'actions',
                    'tanatory_name',
                    'budget_date',
                    'budget_type',
                    'price_references'
                ],
		        options:{
                    columnsClasses: {},
                    sortIcon: {
                        base : 'float-right fa',
                        is: 'fa-sort',
                        up: 'fa-sort-up',
                        down: 'fa-sort-down'
                    },
                	headings:{
                        'actions': 'Acciones',
                        'tanatory_name': 'Nombre de la empresa',
                        'budget_date': 'Fecha del presupuesto',
                        'budget_type': 'Tipo de Presupuesto',
                        'price_references': 'Precio de referencia'
                    },
                    skin: 'table table-striped',
                    filterable:true,
		        	texts: {
                        count: '',
                        filter: 'Buscar: ',
                        filterPlaceholder: "...",
                        defaultOption: 'Elegir {column}',
                        filterBy: "Filtrar por {column}",
                        loading: 'Cargando...',
                        limit: "Mostrando:",
                        noResults: 'No hay resultados'
                    },
                	orderBy:{
                		ascending:0,
                		column:'created_at'
                    },
                    filterByColumn: true,
		        }
        	}
	  	},
	  	filters:{
            dayFormat(input){
                if(input)
                    return moment(input).format('DD/MM/YYYY')
                return "";
            },
        },
        mounted(){
        	this.$root.$on('competitions.search',this.search);
            this.$root.$on('competitions.updated',this.search);
        },
        methods:{
            addColumnsClasses(){
                for(var i in this.columns)
                    this.options.columnsClasses[this.columns[i]]=this.columns[i];
            },
            loadingTable(){
                this.loading = true;
            },
            loadedTable(data){
                this.loading = false;
                this.firsttime = false;

                this.filter = data.config.params.query;
                this.orderBy = data.config.params.orderBy;
                this.ascending = data.config.params.ascending;

                this.$root.$emit('competitions.loaded',data.data);
            },
            search(filter){
                this.$refs.dataTable.setFilter(filter);
            },
            updateColumns(columns){
                var aux = JSON.parse(columns);
                this.columns = Object.keys(aux);
                this.options.headings = aux;
            },
            edit(row){
                this.$root.$emit('competitions.edit',row);
            },
            downloadReport(){
                var win = window.open(this.base + '/report?filter=' + JSON.stringify(this.filter) + '&ascending=' + this.ascending + '&orderBy=' + this.orderBy, '_blank');
                win.focus();
            },
        	deleteRow(row){
	  			var vm=this;
	  			this.$swal({
					title: "Eliminar",
					text: "No podrás deshacer esta acción",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí',
				}).then((result) => {
                    if(result.value){
                        axios({
		                    method: 'delete',
		                    url: this.base + '/' + row.id,
		                    responseType: 'json',
		                }).then(function(response){
		                    vm.$refs.dataTable.refresh();
		                })
		                .catch(function (error) {
		                    vm.$swal(
		                      '¡Ups!',
		                      'Parece que algo fue mal',
		                      'error'
		                    );  
		                });
                    }
				})
	  		}
        }
    }
</script>
