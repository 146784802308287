<template>
	<div class="modal fade action-sheet opax" id="providerModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ provider.id?'Editar proveedor':'Nuevo proveedor'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Nombre *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="provider.name" v-on:blur="firstLetterOnUpperCase('name')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12">
                            <label>Correo electrónico *</label>
                            <input spellcheck="true" type="email" class="form-control" v-model="provider.email"  placeholder="Correo electrónico" v-bind:class="{'is-invalid':errors.email}"/>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('providers.add',this.openModal);
            this.$root.$on('providers.edit',this.openModal);
        },
        data(){
            return {
            	base: window.urls['floral-ornaments-providers.index'],
                saving: false,
                provider:{
                    name: null,
                    email: null,
                },
                errors: {
                    name: false,
                    email: false
                },
            }
        },
        methods:{
            checkIfNumber(evt){
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
        	openModal(data){
                if(data){
                    this.provider = {
                        id: data.id,
                        name: data.name,
                        email: data.email
                    };
                }else{
                    this.provider = {
                        name: null,
                        email: null,
                    };
                }
                this.resetErrors();
        		$('#providerModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors={
                    name: false,
                    email: false,
                };
            },
            firstLetterOnUpperCase(field){
                var aux=this.provider[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.provider[field]=final;
            },
            validate(){
                var valid = true;
                this.errors.name = this.provider.name == null || this.provider.name == "";
                this.errors.email = this.provider.email == null || this.provider.email == "";
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.provider.id) url += '/' + this.provider.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.provider,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('providers.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el proveedor',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#providerModal').modal('hide');
            }
        }
    }
</script>
