<template>
    <div class="row mb-3">
    	<div class="col-12">
    		<p>Mostrando: {{ results.count }} de {{ results.total }}</p>
    	</div>
        <div class="col-12 col-md">
            <div class="row" v-if="searching">
                <div class="form-user col-12 col-md-3">
                    <label>ID</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="ID del contrato" v-model="filter.id" v-on:change="search"/>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Nº Factura</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="Nº Factura" v-model="filter.number" v-on:change="search"/>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Solicitante</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="Solicitante (Nombre o DNI)" v-model="filter.solicitant" v-on:change="search"/>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Fallecido</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="Fallecido (Nombre o DNI)" v-model="filter.deceased" v-on:change="search"/>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Lugar fallecimiento</label>
                    <v-select  v-on:search="searchPlaces" v-bind:options="matchedPlaces" v-model="place" v-on:input="selectPlace" :filter="customSearch">
                        <template slot="spinner">
                            <div class="spinner" v-show="spinner"><i class="fa fa-spinner fa-spin"></i></div>
                        </template>
                    </v-select>
                </div>
                <div class="col-12 mt-3 text-right">
                    <button class="my-auto btn btn-primary" v-on:click="search()" v-bind:disabled="loading">
                        <i v-show="loading" class="fa fa-spin fa-spinner"></i>
                        {{ loading?'Buscando':'Buscar' }}
                    </button>
                    <button class="my-auto btn btn-warning" v-on:click="clean()">Limpiar</button>
                    <button class="my-auto btn btn-danger" v-on:click="toggleSearch()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    	props: ['base'],
        mounted() {
            this.$root.$on('contracts.loaded',this.tableLoaded);
            this.$root.$on('contracts.filter',this.toggleSearch);
            this.getPlaces();
        },
        data(){
        	return {
                basePlaces: window.urls['hospitals.index'],
                searching: true,
                loading: true,
                spinner: false,
                place: null,
                places: [],
                matchedPlaces: [],
                filter: {
                },
                results: {
                    total: 0,
                    count:  0
                },
        	}
	  	},
        methods:{
            customSearch(options, search) {
                return options;
            },
            getPlaces(){
                var vm=this;
                axios({
                    method: 'get',
                    url: this.basePlaces + '/table?query={}&orderBy=name&ascending=1',
                    responseType: 'json',
                }).then(function(response){
                    vm.places = response.data.data;
                })
                .catch(function (error) {
                    vm.getting = false;
                    vm.$swal(
                      '¡Ups!',
                      'Parece que algo fue mal',
                      'error'
                    );  
                }); 
            },
            searchPlaces(input){
                this.spinner = true;
                this.matchedPlaces = [];
                for(var i in this.places){
                    var placeName = this.places[i].name.toLocaleLowerCase();
                    if(placeName.indexOf(input) != -1)
                        this.matchedPlaces.push({
                            code: this.places[i].id,
                            label: this.places[i].name
                        });
                }
                this.spinner = false;
            },
            selectPlace(value){
                if(value) this.filter.id_hospital = value.code;
                else this.filter.id_hospital = null;
                this.search();
            },
        	tableLoaded(data){
        		this.results = data;
        		this.loading = false;
        	},
            downloadExcel(){
                var json=JSON.stringify(this.query)
                window.open(this.base + '/download?query=' + json, '_blank');
            },
            toggleSearch(){
            	this.searching =! this.searching;
            },
            search(){
                this.loading = true;
                this.$root.$emit('contracts.search',this.filter);
            },
            clean(){
            	for(var i in this.filter)
            		this.filter[i] = "";
            	this.search();
            }
        }
    }
</script>
